import { getPlatformLink } from '@firi/shared';
import { Card, VippsButton } from '@firi/ui-web';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { getIconBySanityKey } from 'helpers/icon';
import { sendGTMEvent } from 'helpers/sendGTMEvent';
import { ReactNode } from 'react';
import { SvgType } from 'types/schema';

import { Grid, GridItem } from './Grid';
import { Typography } from './Typography';

const skins = {
  blue: 'bg-firi-blue text-white',
  default: '',
};

interface CardWithTextAndImageProps {
  children: ReactNode;
  title?: string;
  body?: string;
  svgType?: SvgType;
  constrained?: boolean;
  showVippsCta?: boolean;
  skin?: 'blue';
}

export const CardWithTextAndImage = ({
  title,
  body,
  children,
  svgType,
  constrained,
  showVippsCta,
  skin,
}: CardWithTextAndImageProps) => {
  const Illustration = getIconBySanityKey(svgType);

  return (
    <Card
      as="article"
      className={clsx(skins[skin || 'default'], !constrained ? 'lg:p-10' : 'lg:p-20')}
    >
      <Grid>
        <GridItem className={clsx(!constrained ? '!col-span-9' : '!col-span-7')}>
          <div className="h-full flex flex-col justify-between">
            <div>
              <div className="mb-6 flex justify-between items-center">
                <Typography size="xl" as="h1" color={skin === 'blue' ? 'white' : 'blue'}>
                  {title}
                </Typography>
              </div>
              <div className="mb-8">
                <Typography color={skin === 'blue' ? 'white' : 'blue'} size="md">
                  {body}
                </Typography>
              </div>
            </div>
            {children}
          </div>
        </GridItem>
        {svgType && (
          <GridItem
            className={clsx('hidden md:block', !constrained ? '!col-span-3' : '!col-span-5')}
          >
            <div className="flex justify-end">
              <Illustration className="max-h-[240px]" />
            </div>
          </GridItem>
        )}

        {showVippsCta && (
          <GridItem
            className={clsx(
              'flex justify-center items-center',
              !constrained ? '!col-span-3' : '!col-span-5',
            )}
          >
            <VippsButton
              vippsAction="register"
              href={getPlatformLink({ link: 'vipps-signup' })}
              aria-label={t`To sign up with Vipps`}
              onClick={() => {
                sendGTMEvent({
                  event_action: `Signup clicks`,
                  event_label: 'Signup with Vipps',
                  event_category: 'Identification',
                  signup_method: 'vipps',
                });
              }}
            />
          </GridItem>
        )}
      </Grid>
    </Card>
  );
};
