import collectingIllustration from '@firi/shared/assets/illustrations/coins.svg';
import bookIllustration from '@firi/shared/assets/illustrations/crypto-101.svg';
import skribbleIllustration from '@firi/shared/assets/illustrations/draw-profile.svg';
import hammerIllustration from '@firi/shared/assets/illustrations/hammer.svg';
import keyIllustration from '@firi/shared/assets/illustrations/hand-key.svg';
import helloIllustration from '@firi/shared/assets/illustrations/hello.svg';
import magnifyingIllustration from '@firi/shared/assets/illustrations/magnifier-glass.svg';
import mitIdIllustration from '@firi/shared/assets/illustrations/mit-id.svg';
import newspaperIllustration from '@firi/shared/assets/illustrations/newspaper.svg';
import parachuteIllustration from '@firi/shared/assets/illustrations/parachute.svg';
import thumbsDownIllustration from '@firi/shared/assets/illustrations/screen-thumbs-down.svg';
import thumbsUpIllustration from '@firi/shared/assets/illustrations/screen-thumbs-up.svg';
import { SvgType } from 'types/schema';

export const getIconBySanityKey = (iconKey?: SvgType) => {
  switch (iconKey) {
    case 'book':
      return bookIllustration;
    case 'coinhammer':
      return hammerIllustration;
    case 'coinwave':
      return helloIllustration;
    case 'collectingCoins':
      return collectingIllustration;
    case 'key':
      return keyIllustration;
    case 'magnifying':
      return magnifyingIllustration;
    case 'newspaper':
      return newspaperIllustration;
    case 'parachute':
      return parachuteIllustration;
    case 'skribble':
      return skribbleIllustration;
    case 'thumbsup':
      return thumbsUpIllustration;
    case 'thumbsdown':
      return thumbsDownIllustration;
    case 'mit-id':
      return mitIdIllustration;
    default:
      break;
  }
  console.warn('Missing icon:', iconKey);
  return null;
};
